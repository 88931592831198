/*.imgContainer{*/
/*    width:300px;*/
/*    margin: 0 auto 25px;*/
/*    overflow: hidden;*/
/*    padding-top: 37.75px;*/
/*}*/

/*.imgItem{*/
/*    width:100%;*/

/*}*/


.restoreTitle{
    padding: 15px 0 5px;
    text-align: center;
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 18px;
    line-height: 20px;
}
