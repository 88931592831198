* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}

@font-face {
    font-family: "AktivGroteskEx_A_Bd";
    src: local("AktivGroteskEx_A_Bd"), url("./common/fonts/AktivGroteskEx_A_Bd.ttf");
}

@font-face {
    font-family: "AktivGroteskEx_A_Lt";
    src: local("AktivGroteskEx_A_Lt"), url("./common/fonts/AktivGroteskEx_A_Lt.ttf");
}

@font-face {
    font-family: "AktivGroteskEx_A_Md";
    src: local("AktivGroteskEx_A_Md"), url("./common/fonts/AktivGroteskEx_A_Md.ttf");
}

@font-face {
    font-family: "AktivGroteskEx_A_Rg";
    src: local("AktivGroteskEx_A_Rg"), url("./common/fonts/AktivGroteskEx_A_Rg.ttf");
}

@font-face {
    font-family: "AktivGroteskEx_A_XBd";
    src: local("AktivGroteskEx_A_XBd"), url("./common/fonts/AktivGroteskEx_A_XBd.ttf");
}

.app {
    position: relative;
    height: var(--app-height);
    width: var(--app-width)
}

.landscapeMode{

}

.container{

}





.imgContainer {
    width: 257px;
    margin: 0 auto;
    padding-top: 60px;
}

.logo {
    width: 100%

}

.text{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    color: var(--secondary01);
    margin-top: 34px;
}

.subText{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    color: var(--secondary01);
    margin-top: 0px;
}





:root {
    /*questionnaire variables*/
    --primary000: #020A14;
    --primary001: #21252A;
    --primary002: #5A636E;
    --primary00: #161616;
    --primary01: #1B1F22;
    --primary02: #383F46;
    --primary03: #86919e;
    --primary04: #3A3C3E;
    --primary50: #ffffff;

    /*report variables*/
    --primary100: #f3f5f8;
    --primary100-opacity1: #f3f5f8c2;
    --primary200: #e7e7e7;
    --primary300: #eff1f2;
    --primary400: #bbbbbb;
    --primary600: #939393;
    --primary800: #444444;
    --primary850: #6c6b6b;
    --primary900: #666666;
    --primary910: #777777;
    --primary950: #999999;
    --primary1000: #000000;

    /*report variables*/
    --secondary06: #00CAB3;
    --secondary07: #27f3ce;
    --secondary08: #65e0d1;
    --secondary09: #01bca7;
    --secondary10: #3ecba1;
    --secondary11: #08a764;
    --secondary12: #10db86;
    --secondary13: #42d0b8;
    --secondary14: #0da488;
    --secondary15: #0e836e;
    --secondary19: #019cef;
    --secondary20: #2c7cfe;
    --secondary21: #2479f0;
    --secondary22: #2a53e2;
    --secondary23: #778bd2;
    --secondary24: #506bca;
    --secondary25: #183fc8;
    --secondary26: #0f44ff;
    --secondary30: #9144fc;
    --secondary31: #690996;
    --secondary32: #5b0e80;
    --secondary33: #5b0e80;
    --secondary40: #ec486e;
    --secondary50: #f08624;

    /*questionnaire variables*/
    --secondary000: #fff;
    --secondary001: #f5f5f5;
    --secondary002: #ebeaea;
    --secondary00: #e6f1ff;
    --secondary01: #B7C4D2;
    --secondary02: #5795EB;
    --secondary03: #3678D5;
    --secondary03-hover: #1E5AAF;
    --secondary03-active: #1879FF;
    --secondary04: #071F41;
    --secondary05: #0E366E;
    --error00: #EB5788;

    --border00: rgba(255, 255, 255, 0.06);

    --shadow01: rgba(255, 255, 255, 0.1);
    --shadow02: rgba(18, 20, 23, 0.1);
    --shadow03: rgba(0, 0, 0, 0.25);
    --shadow04: rgba(0, 0, 0, 0.06);
    --shadow05: rgba(255, 255, 255, 0.06);
    --shadow06: rgba(0, 0, 0, 0.5);

    /*report variables*/
    --NEUTRAL_BG_MAIN: #121417;
    --NEUTRAL_TEXT_STRONG: #E6F1FF;
    --NEUTRAL_BORDER_MAIN: #BBC2C9;

    --app-height: 100%;
    --app-width:100%;
}



